import store from 'client/store'
import auth from 'client/auth'

import find from 'lodash/find'

export default [
  {
    path: '/duels/:eventId',
    component: () => import(/* webpackChunkName: "duels" */ 'client/duels/wrapper.vue'),
    beforeEnter: function (to, from, next) {
        auth.check().then(() => {
            store.dispatch("events.getById", {
              eventId: to.params.eventId,
            }).then(() => {
              const query = { eventId: to.params.eventId }
              Promise.all([
                store.dispatch("eventDisciplines.get", query),
                store.dispatch("members.get", query),
                store.dispatch("groups.get", query),
                store.dispatch("clubs.get", query),
                store.dispatch("teams.get", query),
                store.dispatch("sessions.getByEvent", query),
              ]).then(() => {
                next()
              })
            })
        }).catch(function () {
            next({
                path: '/login'
            })
        })
    },
    children: [{
      path: '/',
      name: 'duels.sessions',
      component: () => import(/* webpackChunkName: "duels" */ 'client/duels/sessions.vue'),
    }, {
      path: 'session/:sessionId/:rotation',
      name: 'duels.session',
      component: () => import(/* webpackChunkName: "duels" */ 'client/duels/session.vue'),
      beforeEnter: function (to, from, next) {
        const session = find(store.state.sessions.items, item => item.id === to.params.sessionId)
        const query =  { eventDisciplineId: session.eventDisciplineId }
        const sQuery = { sessionId: to.params.sessionId }

        Promise.all([
          store.dispatch("exercises.get", query),
          store.dispatch("eventDiscipline.get", query),
          store.dispatch("categories.get", query),
          store.dispatch("participations.get", query),
          store.dispatch("blocks.get", query),
          store.dispatch("blockParticipations.get", query),
          store.dispatch("rankings.get", query),
          store.dispatch("panels.getBySession", sQuery),
          store.dispatch("duels.get", sQuery),
        ]).then(next)
      },
    }]
  }
]
