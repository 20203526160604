import JL from 'jsnlog';
if (window && !window.onerror) {
  window.onerror = function (errorMsg, url, lineNumber, column, errorObj) {
    JL("onerrorLogger").fatalException({
      "msg": "Uncaught Exception",
      "errorMsg": errorMsg, "url": url,
      "line number": lineNumber, "column": column
    }, errorObj);

    return false;
  }
}

import Vue from 'vue';
import focus from 'vue-focus';
import VueResource from 'vue-resource';
import VueProgressBar from 'vue-progressbar';
import VueResourceProgressBarInterceptor from 'vue-resource-progressbar-interceptor';
import VeeValidate from 'vee-validate';
import Vddl from 'vddl';
import VueScrollTo from 'vue-scrollto';
import VueI18n from 'vue-i18n';
import modals from '@/components/modals';
import navigate from '@/components/navigate';
import notify from '@/components/notify';
import VTooltip from 'v-tooltip'

Vue.use(VTooltip, {
  defaultClass: 'c-tooltip',
  defaultPlacement: 'bottom',
})
Vue.use(VueResource);
Vue.use(VueProgressBar);
Vue.use(VueResourceProgressBarInterceptor)

import store from '@/store'
import router from './router'

console.log(router)

// Add drag and drop library
Vue.use(Vddl);

import {polyfill} from 'mobile-drag-drop';
// optional import of scroll behaviour
import {scrollBehaviourDragImageTranslateOverride} from 'mobile-drag-drop/scroll-behaviour';
// options are optional ;)
polyfill({
  // use this to make use of the scroll behaviour
  dragImageTranslateOverride: scrollBehaviourDragImageTranslateOverride
});

Vue.use(VeeValidate);
Vue.use(focus)
Vue.use(modals)

Vue.use(VueScrollTo)
Vue.use(VueI18n)
Vue.use(notify)

const messages = {
  en: require('/locales/files/en.json'),
  de: require('/locales/files/de.json'),
  nl: require('/locales/files/nl.json'),
  'nl-BE': require('/locales/files/nl-BE.json'),
  fr: require('/locales/files/fr.json'),
  'fr-BE': require('/locales/files/fr-BE.json'),
}

// Create VueI18n instance with options
let i18n = new VueI18n({
  locale: 'en', // set default locale, override in routing based on account or context language preferences
  messages: messages,
  silentTranslationWarn: true,
})
Vue.i18n = i18n

import app from './app'
import '@/components/form'
import '@/components/waiting'
import 'styles/main.scss'

store.dispatch('environment.get').then(() => {
  Vue.use(navigate, router);
  new Vue({
    components: {app},
    router,
    i18n,
    store,
    render: h => h(app),
  }).$mount('#app')
})

